<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="channel-choice-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Envoyez votre Message Via" gender="F" />
          </div>
          <div class="col-md-12">
            <div class="alert alert-info" v-if="remainingMessages == null">
              <div class="text-center">Veuillez s'il vous plaît choisir le canal d'envoi !</div>
            </div>
            <div class="alert alert-success" v-if="remainingMessages > 0">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages == 0">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages < 0">
              <div
                class="text-center"
              >Veuillez immédiatement contacter l'administrateur svp au 0709525460 !!!!</div>
            </div>
          </div>
          <div class="row" v-if="channels != null && customerChannels != null">
           
            
            <div class="col-md-4 text-center" v-for="(channel, key) in channels" :key="key">
              <div class="form-group">
                <label :for="'channel'+key">
                  <img :src="channel.image" style="width:70%;" alt srcset />
                  <br />
                  <br />
                  <input
                    @change="onChannelChange(channel)"
                    :value="channel"
                    type="radio"
                    name="channel"
                    :id="'channel'+key"
                  />&nbsp;
                  <label :for="'channel'+key" style="font-size:16px;">{{ channel.name }}</label>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="channels == null || customerChannels == null" class="col-md-12" >
            <div class="col-md-12 text-center">
              <moonloader/>
            </div>
            <!-- <p class="text-center">Chargement en cours ...</p> -->
          </div>
          <div class="col-md-12">
            <button
              class="primary-button pull-right"
              @click="goToMessageBuilder"
              
            >
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button>
            <!-- <button class="primary-button pull-right" v-else disabled>
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.channel-choice-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
</style>

<script>
import { defineComponent } from 'vue'
import axios from "axios";
import { COMMUNICATION, API_PREFIX, API_VERSION } from "@/config";

export default defineComponent({
  name: "ChannelChoiceComponent",
  components: {},
  data: function() {
    return {
      channels: null,
      customerChannels: null,
      channel: null,
      remainingMessages: null
    };
  },
  mounted: function() {
    
    this.getChannels().then(() => {
      this.getCustomerChannels();
    });
  },
  methods: {
    getChannels: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        axios
          .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "channels",{
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          })
          .then(response => {
            app.channels = response.data;
            resolve(app.channels);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    goToMessageBuilder: function() {
      let app = this;
      app.$emit("go-to-message-builder", app.channel,);
    },
    getCustomerChannels: function() {
      let self = this;
      self.isLoading = true;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(function(response) {
          self.customerChannels = response.data;
          self.isLoading = false;
        })
        .catch(function(error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    disabledChannel: function(channel) {
      this.customerChannels.forEach(item => {
        if (channel.id == item.id) {
          return item.pivot.remaining_messages;
        }
      });
    },
    onChannelChange: function(channel) {
      this.channel = channel;
      this.customerChannels.forEach(item => {
        if (channel.id == item.id) {
          this.remainingMessages = item.pivot.remaining_messages;
        }
      });
    }
  }
});
</script>
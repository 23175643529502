<template>
    <div>
         
        <Breadcrumb bgClass="bg-image--contact-1" titre="MON COMPTE"></Breadcrumb>

        <div class="container p-3" style="padding: 25px;">
            <big-title label="ACTIVATION DE COMPTE" />
            <br><br>
            <div v-if="isLoading == false">
                <div class="card-container p-3">
                    <div class="row" v-if="displayMessage == null">
                        <div class="col-md-12">
                            <div class="text-center">
                                <img src="/images/activate-success.png" alt="" style="height: 350px;">
                            </div>
                            <div class="text-center pb-2" style="font-size: 16px;">
                                Felicitations, votre compte Jours de Joie est maintenant activé !
                            </div>
                            <div class="text-center py-2">
                                <button class="primary-button" @click="goToHome">
                                    ALLER AU CARNET NUPTIAL
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="alert" :class="'alert-' + displayMessage.type">
                            <center>
                                {{ displayMessage.content }}
                            </center>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <spinner size="medium" line-fg-color="#b71c1c" /><br><br>
            </div>
        </div>
        <!-- <NewsletterBox></NewsletterBox> -->
    </div>
</template>

<script>
    import NewsletterBox from '@/components/NewsletterBox.vue';
    import Breadcrumb from '@/components/Breadcrumb.vue';
    import axios from 'axios';
    import { AUTH, NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from '@/config';

    export default {
        name: 'AccountInfoEdit',
        components : {
            NewsletterBox,
            Breadcrumb
        },
        data: function() {
            return {
                user: JSON.parse(this.$store.state.user),
                customer: null,
                isLoading: false,
                displayMessage: null,
                index:0
            }
        },
        mounted: function () {
            this.activateAccount();
        },
        computed: {
            //
        },
        methods: {
            goToHome() {
                window.location.href = '/'
            },
            activateAccount: function() {
                if(this.$route.params.emailVerificationToken == null) {
                    this.isLoading = false;
                    this.displayMessage = {
                        type: 'danger',
                        content: 'Erreur lors de l\'activation du compte ! Aucun token et/ou email retrouvé sur cette page.'
                    }
                    this.$createToast(
                        {
                        title: 'Attention !',
                        description: "Erreur lors de l'enregistrement des informations !"
                        },
                        {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                        })
                    // this.$toasted.error('Erreur lors de l\'activation du compte !');
                } else {
                    this.isLoading = true;
                   
                    let data = {
                        token: this.$route.params.emailVerificationToken,
                        email: this.$route.params.email
                    };
                    axios.get(AUTH.DEV_URL + API_PREFIX + API_VERSION + 'email-verification/' + data.token + '/' + data.email)  
                        .then((response) => {
                            // this.$store.dispatch('get_user_info')
                            //     .then(() => {
                                console.log(response)
                                    this.isLoading = false;
                                    if (response.data.success) {
                                            this.$createToast(
                                                {
                                                title: 'Operation reussie !',
                                                description: "Compte activé avec succès !"
                                                },
                                                {
                                                position: 'top-center',
                                                type: 'success',
                                                timeout: 10000,
                                                transition: 'bounce',
                                                }
                                            )

                                            this.$router.push(
                                                '/'
                                            );
                                    }else{
                                        this.$createToast(
                                        {
                                        title: 'Attention !',
                                        description: "Erreur lors de l\'activation du compte !"
                                        },
                                        {
                                        position: 'top-center',
                                        type: 'error',
                                        timeout: 10000,
                                        transition: 'bounce',
                                        })
                                    }
                                 
                                    
                                // })
                                // .catch(() => {
                                    
                                // })
                        })
                        .catch((error) => {
                            console.log(error)
                            this.isLoading = false;
                            this.$createToast(
                            {
                            title: 'Attention !',
                            description: "Erreur lors de l\'activation du compte !"
                            },
                            {
                            position: 'top-center',
                            type: 'error',
                            timeout: 10000,
                            transition: 'bounce',
                            })
                            //this.$toasted.error(' !');
                        });
                }
            },
            finish: function(){
                 window.location.href = '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances';
            }
        }
    }
</script>

<template>
  <div class="py-2 flex flex-col w-full text-sm px-1">
    <button class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer" @click="toggleDisplay">
      <span class="text-left">Communes </span>&nbsp;
      <i :class="isDisplay ? 'fa-angle-up' : 'fa-angle-down'" class="text-gray-600 fa"></i>
      <!-- <font-awesome-icon class="text-gray-600" :icon="isDisplay ? 'fa-angle-up' : 'fa-angle-down'"></font-awesome-icon> -->
    </button>
    <div class="py-2 flex flex-col" v-show="isDisplay">
      <div class="py-1 hover:text-red-800" v-for="(town, key) in data" :key="key">
        <button
            @click="onTownFilterSelected(town.id,town.name)" class="filter-item-action bg-white "
            :class="{'text-red-800': currentTownId === town.id}"
        >
          {{ town.name }}&nbsp;&nbsp;
          <i v-if="currentTownId === town.id" class="fa fa-close"></i>
          <!-- <font-awesome-icon v-if="currentTownId === town.id" icon="fa-close"></font-awesome-icon> -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { convertTownToTownship } from "@/views/wedding-provider/utils";

export default {
  name: 'FilterItemsComponent',
  props: {
    data: {
      type: Array,
      required: true
    },
    currentTownId: {
      type: Number,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDisplay: this.isOpen
    }
  },
  mounted(){
    
  },
  methods: {
    onTownFilterSelected(townId,townName) {
      // this.$ua.trackEvent("Ville", "Click", townName, null);
      this.$emit('town-filter-selected', townId);
    },
    convertTownToTownshipWrapper(name) {
      return convertTownToTownship(name);
    },
    toggleDisplay() {
      this.isDisplay = !this.isDisplay
    }
  }
}
</script>

<style>
.filter-header {
  width: 100%;
}
.filter-item {
  padding: 0 0.25rem;
}
.filter-item:hover {
  color: rgb(183 28 28 / 100%);
}
.filter-body {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  align-items: center;
}

.filter-item-action {
  padding: 0;
}

.filter-item-action.active {
  color: rgb(183 28 28 / 100%);
  border: 1px solid rgb(183 28 28 / 100%);
  padding: 0.25rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
</style>

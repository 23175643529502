<template>
  <div class="text-center" style="text-align: center;padding-top: 20px;">
    <img
      :src="COMMUNICATION + weddingCard.cards[0].file"
      alt=""
      srcset=""
    /><br />
    <button
      type="button"
      class="secondary-button"
      data-toggle="modal"
      data-target="#exampleModal"
      data-whatever="@mdo"
    >
      Confirmer votre presence
    </button>
    <br />
    <button class="primary-button" style="margin-top: 20px;" @click="goToHome">
      <i class="fa fa-angle-left" aria-hidden="true" style="color: #fff;"></i>
      Retourner sur Jours de Joie
    </button>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Invité</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="col-md-12 text-left">
                <select-input
                  label="Civilité"
                  :name="'civility'"
                  type="text"
                  :rules="'required'"
                  :isRequired="true"
                  :options="civilities"
                  v-model="guestsForms[0].civility"
                />
              </div>
              <div class="d-flex">
                <div class="col-md-6 text-left">
                  <text-input
                    label="Nom"
                    :name="'last_name'"
                    type="text"
                    :rules="'required'"
                    :isRequired="true"
                    v-model="guestsForms[0].last_name"
                  />
                </div>
                <div class="col-md-6 text-left">
                  <text-input
                    label="Prénoms"
                    :name="'given_names'"
                    type="text"
                    :rules="'required'"
                    :isRequired="true"
                    v-model="guestsForms[0].given_names"
                  />
                </div>
              </div>
              <div class="col-md-12 text-left">
                <text-input
                  label="Email"
                  :name="'email'"
                  type="email"
                  :rules="'email'"
                  :isRequired="false"
                  v-model="guestsForms[0].email"
                />
              </div>
              <div class="d-flex">
                <div class="col-md-6 text-left">
                  <masked-input
                    label="Téléphone principal"
                    :name="'phone_number_1'"
                    type="text"
                    :rules="'required|min:21|max:22'"
                    :isRequired="true"
                    placeholder="(+225) 00 00 00 00 00"
                    v-model="guestsForms[0].phone_number_1"
                    :mask="'+{(000)} 00 00 00 00 00'"
                  />
                </div>
                <div class="col-md-6 text-left">
                  <masked-input
                    label="Téléphone secondaire"
                    :name="'phone_number_2'"
                    type="text"
                    :rules="'min:21|max:22'"
                    :isRequired="false"
                    placeholder="(+225) 00 00 00 00 00"
                    v-model="guestsForms[0].phone_number_2"
                    :mask="'+{(000)} 00 00 00 00 00'"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="store"
              :disabled="isLoading"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  COMMUNICATION,
  API_PREFIX,
  API_VERSION,
  NUPTIAL_NOTEBOOK
} from "@/config";
import axios from "axios";
export default {
  name: "weddingCardShow",
  data: function() {
    return {
      weddingCard: null,
      COMMUNICATION: COMMUNICATION.DEV_URL,
      guestsForms: [
        {
          has_confirmed: 1,
          phone_number_2: null,
          email: null,
          event_id: null,
          guest_id: 2
        }
      ],
      isLoading: false,

      civilities: [
        {
          id: "Monsieur",
          name: "Monsieur"
        },
        {
          id: "Madame",
          name: "Madame"
        },
        {
          id: "Mademoiselle",
          name: "Mademoiselle"
        }
      ]
    };
  },
  mounted: function() {
    this.getMessageInfos();
  },
  methods: {
    getMessageInfos: function() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "messages/" +
            app.$route.params.weddingCardId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
          app.weddingCard = response.data;
          
        })
        .catch(error => {
          console.log(error);
        });
    },
    goToHome() {
      this.$router.push("/");
    },
    store: function() {
      let app = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.isLoading = true;
          app.guestsForms[0].customer_id = app.weddingCard.customer_id;

          axios
            .post(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-store",
              app.guestsForms,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token
                }
              }
            )
            .then(() => {
              this.isLoading = false;
              // this.$router.push('/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage')

              this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Informations ajouté avec succès !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              })
              window.location.href = "/";
            })
            .catch(error => {
              console.log(error.response.data);
              this.isLoading = false;
              this.$createToast(
                {
                title: 'Attention !',
                description: "Erreur lors de l'ajout de l'invité !"
                },
                {
                position: 'top-center',
                type: 'error',
                timeout: 10000,
                transition: 'bounce',
                }
              )
            });
        }
      });
    }
  }
};
</script>

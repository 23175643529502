export const MAIRIE_URL_API = "https://town-hall.joursdejoie.com/api/v1/";
// export const MAIRIE_URL_API = "https://backend-unified.paraclet-bk.com/public/api/v1/";

export const MAIRIE_URL = "https://town-hall.joursdejoie.com/";
// export const MAIRIE_URL = "https://backend-unified.paraclet-bk.com/public/";

// export const  CARNET_NUPTIAL_URL = 'https://backend-unified.paraclet-bk.com/public/';
// export const  CARNET_NUPTIAL_URL_API = 'https://backend-unified.paraclet-bk.com/public/api/';

export const CARNET_NUPTIAL_URL = "https://nuptial-notebook.joursdejoie.com/";
export const CARNET_NUPTIAL_URL_API = "https://nuptial-notebook.joursdejoie.com/api/";

export const AUTH = {
    DEV_URL: "https://auth.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://auth.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://auth.joursdejoie.paraclet-bk.com/",
};

export const NUPTIAL_NOTEBOOK = {
    DEV_URL: "https://nuptial-notebook.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://nuptial-notebook.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://nuptial-notebook.joursdejoie.paraclet-bk.com/",
};

export const COMMUNICATION = {
    DEV_URL: "https://communication.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://communication.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://communication.joursdejoie.paraclet-bk.com/",
};

export const SHOPPING = {
    DEV_URL: "https://shopping.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://shopping.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://shopping.joursdejoie.paraclet-bk.com/",
};

export const PAYMENT = {
    DEV_URL: "https://payment.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://payment.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://payment.joursdejoie.paraclet-bk.com/",
};

export const CONTACT = {
    DEV_URL: "https://contact.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://contact.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://contact.joursdejoie.paraclet-bk.com/",
};
export const PROVIDER = {
    DEV_URL: "https://wedding-providers.joursdejoie.com/",
    // DEV_URL: "https://backend-unified.paraclet-bk.com/public/",
    TEST_URL: "https://wedding-providers.joursdejoie.com/",
    PROD_URL: "https://wedding-providers.joursdejoie.com/",
};

export const XIKKA_API_KEY = "Z9-wngVJ0hctK625j8ORWQ";
export const STATUS_COMMANDE = 1;
export const STATUS_PAYEMENT_SUCCESS = 2;
export const STATUS_PAYEMENT_DENIED = 3;
export const STATUS_PAYEMENT_PENDING = 4;
export const TVA_ID = 1;
export const API_PREFIX = "api/";
export const API_VERSION = "v1/";

export const LOCALHOST_API={
    BASE_URL: 'http://127.0.0.1:8000',
    API_PREFIX: 'api/',
    API_VERSION: 'v1/',
}

export const PUSHER={
    cluster: "eu",
    app_id : "1000918",
    key : "c283b23cd087a7cdbc4f",
    secret : "ee50e718ff97fe4dc46c"
};
<template>
    <div>
        <div v-if="index % 2 == 0">
            <br>
            <div class="rcommittee-container">
                <div class="row" v-if="isLoading == false && rcommittee">
                    <div class="p-3 col-md-8">
                        <div class="col-md-12">
                            <sub-title 
                                :label="rcommitteeProps.name"
                                :gender="rcommitteeProps.gender"
                            />
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <select-input
                                        label="Civilité" name="civility" type="text"
                                        :rules="'required'" :isRequired="true" :options="civilities"
                                        v-model="rcommittee.civility"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        label="Nom" name="last_name" type="text"
                                        :rules="'required'" :isRequired="true"
                                        v-model="rcommittee.last_name"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <text-input
                                        label="Prénoms" name="given_names" type="text"
                                        :rules="'required'" :isRequired="true"
                                        v-model="rcommittee.given_names"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        label="Email" name="email" type="email"
                                        :rules="'email'" :isRequired="false"
                                        v-model="rcommittee.email"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <masked-input
                                        label="Nº de téléphone principal" name="phone_number_1" type="text"
                                        :rules="'required|min:21|max:22'" :isRequired="true" 
                                        :mask="'+{(000)} 00 00 00 00 00'"
                                        v-model="rcommittee.phone_number_1" placeholder="+(225) 01 00 00 00 01"
                                       
                                    />
                                </div>  
                                <div class="col-md-6">
                                    <masked-input
                                        label="Nº de téléphone secondaire" name="phone_number_2" type="text"
                                        :rules="'min:21|max:22'" :isRequired="false" 
                                        :mask="'+{(000)} 00 00 00 00 00'"
                                        v-model="rcommittee.phone_number_2" placeholder="+(225) 01 00 00 00 01"
                                       
                                    />
                                </div>
                            </div>
                            <center>
                                <button class="primary-button" @click="updateRestrictedCommittee">ENREGISTRER</button>&nbsp;
                                <button class="secondary-button">ANNULER</button>
                            </center>
                        </div>
                    </div>
                    <div class="col-md-4" :style=" image ? '': rcommittee.image ? '' : 'background-color: #f2f2f2'">
                        <!-- <img v-if="rcommitteeProps.gender == 'M' && index == 0" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-1.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'F' && index == 1" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-2.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'M' && index == 2" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-3.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'F' && index == 3" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-4.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'M' && index == 4" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-5.jpg" alt=""> -->
                        <div >
                            <div class="helper"></div>
                            <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                                <div class="helper"></div>
                                <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                    <img :src="image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                <label v-else-if="!rcommittee.image" class="btn display-inline">
                                        Choisir une image
                                    <input type="file" name="image" @change="onChange">
                                </label>
                                
                                <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                    <img :src="NUPTIAL_NOTEBOOK.DEV_URL+rcommittee.image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <center v-else> 
                    <div style="padding:50px;">
                        <spinner  />
                    </div>
                </center>
            </div>
            <br><br>
        </div>
        <div v-else>
            <br>
            <div class="rcommittee-container">
                <div class="row" v-if="isLoading == false && rcommittee">
                    <div class="col-md-4" :style=" image ? '': rcommittee.image ? '' : 'background-color: #f2f2f2'">
                        <!-- <img v-if="rcommitteeProps.gender == 'M' && index == 0" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-1.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'F' && index == 1" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-2.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'M' && index == 2" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-3.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'F' && index == 3" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-4.jpg" alt="">
                        <img v-else-if="rcommitteeProps.gender == 'M' && index == 4" style="width:100%;height:100%;" src="/images/nuptial_notebook/cr-5.jpg" alt=""> -->
                        <div >
                            <div class="helper"></div>
                            <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                                <div class="helper"></div>
                                <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                    <img :src="image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                <label v-else-if="!rcommittee.image" class="btn display-inline">
                                        Choisir une image
                                    <input type="file" name="image" @change="onChange">
                                </label>
                                
                                <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                    <img :src="NUPTIAL_NOTEBOOK.DEV_URL+rcommittee.image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="p-3 col-md-8">
                        <div class="col-md-12">
                            <sub-title 
                                :label="rcommitteeProps.name"
                                :gender="rcommitteeProps.gender"
                            />
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <select-input
                                        label="Civilité" name="civility" type="text"
                                        :rules="'required'" :isRequired="true" :options="civilities"
                                        v-model="rcommittee.civility"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        label="Nom" name="last_name" type="text"
                                        :rules="'required'" :isRequired="true"
                                        v-model="rcommittee.last_name"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <text-input
                                        label="Prénoms" name="given_names" type="text"
                                        :rules="'required'" :isRequired="true"
                                        v-model="rcommittee.given_names"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        label="Email" name="email" type="email"
                                        :rules="'email'" :isRequired="false"
                                        v-model="rcommittee.email"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <masked-input
                                        label="Nº de téléphone principal" name="phone_number_1" type="text"
                                        :rules="'required|min:21|max:22'" :isRequired="true" 
                                        :mask="'+{(000)} 00 00 00 00 00'"
                                        v-model="rcommittee.phone_number_1" placeholder="+(225) 01 00 00 00 01"
                                       
                                    />
                                </div>
                                <div class="col-md-6">
                                    <masked-input
                                        label="Nº de téléphone secondaire" name="phone_number_2" type="text"
                                        :rules="'min:21|max:22'" :isRequired="false" 
                                        :mask="'+{(000)} 00 00 00 00 00'"
                                        v-model="rcommittee.phone_number_2" placeholder="+(225) 01 00 00 00 01"
                                       
                                    />
                                </div>
                            </div>
                            <center>
                                <button class="primary-button" @click="updateRestrictedCommittee">ENREGISTRER</button>&nbsp;
                                <button class="secondary-button">ANNULER</button>
                            </center>
                        </div>
                    </div>
                </div>
                <center v-else>
                    <div style="padding:50px;">
                        <spinner  />
                    </div>
                </center>
            </div>
            <br><br>
        </div>
    </div>
</template>

<style scoped>
    .rcommittee-container {
        -webkit-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        -moz-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        background:#fff;
    }
</style>

<script>
    import axios from 'axios';
    import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";

    export default {
        name: 'RestrictedCommitteeComponent',
        props: ['rcommitteeProps', 'index'],
        components: {
            // vueDropzone: vue2Dropzone
        },
        data: function() {
            return {
                NUPTIAL_NOTEBOOK:NUPTIAL_NOTEBOOK,
                civilities: [{
                    id: 'M',
                    name: 'Monsieur'   
                }, {
                    id: 'Mme',
                    name: 'Madame'
                }, {
                    id: 'Mlle',
                    name: 'Mademoiselle'
                }],
                rcommittee: null,
                isLoading: false,
                image: '',
                imageUpload:null,
            }
        },
        mounted: function() {
            this.rcommittee = {
                event_id: this.rcommitteeProps.pivot.event_id,
                restricted_committee_id: this.rcommitteeProps.pivot.restricted_committee_id,
                civility: this.rcommitteeProps.pivot.civility,
                last_name: this.rcommitteeProps.pivot.last_name,
                given_names: this.rcommitteeProps.pivot.given_names,
                email: (this.rcommitteeProps.pivot.email == null) ? this.rcommitteeProps.pivot.email : '',
                phone_number_1: this.rcommitteeProps.pivot.phone_number_1,
                phone_number_2: (this.rcommitteeProps.pivot.phone_number_2 == null) ? this.rcommitteeProps.pivot.phone_number_2 : '',
                image: (this.rcommitteeProps.pivot.image == null) ? '' :this.rcommitteeProps.pivot.image 
            };
            console.log(this.rcommitteeProps)
        },
        computed: {
            //
        },
        methods: {
            onDrop: function(e) {
                e.stopPropagation();
                e.preventDefault();
                console.log(files[0])
                this.image = files[0]
                this.imageUpload = files[0]
                var files = e.dataTransfer.files;
                this.createFile(files[0]);
            },
            onChange(e) {
                var files = e.target.files;
                console.log(files[0])
                this.image = files[0]
                this.imageUpload = files[0]
                this.createFile(files[0]);
            },
            createFile(file) {
                if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
                }
                var img = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = function(e) {
                  vm.image = e.target.result;
                }
                reader.readAsDataURL(file);
            },
            removeFile() {
                this.rcommittee.image = '';
                this.image = '';
            },
            updatedPercentage(customerId){
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'percentage/' + customerId,{
                        headers: {
                        Authorization: "Bearer " + this.$store.state.token
                        }
                    })
                    .then(() => {
                        
                    })
                    .catch(() => {
                    });
            },
            archivated: function(customerId){
                this.$store.dispatch('archiveAccount', customerId)
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
            updateRestrictedCommittee: function() {
                
                this.isLoading = true;
                let rcommitteeForm = new FormData;
                rcommitteeForm.append('event_id', this.rcommittee.event_id);
                rcommitteeForm.append('restricted_committee_id', this.rcommittee.restricted_committee_id);
                rcommitteeForm.append('civility', this.rcommittee.civility);
                rcommitteeForm.append('last_name', this.rcommittee.last_name);
                rcommitteeForm.append('given_names', this.rcommittee.given_names);
                rcommitteeForm.append('email', this.rcommittee.email);
                rcommitteeForm.append('phone_number_1', this.rcommittee.phone_number_1);
                rcommitteeForm.append('phone_number_2', this.rcommittee.phone_number_2);
                rcommitteeForm.append('image', this.imageUpload);
                axios.post(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'restricted-committees/events', rcommitteeForm, {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.token
                        }
                })
                .then((response) => {
                    this.isLoading = false
                    this.updatedPercentage(response.data.customer_id)
                    this.archivated(response.data.customer_id)
                    this.$createToast(
                                    {
                                    title: 'Operation reussie !',
                                    description: "Informations enregistrées avec succès !  !"
                                    },
                                    {
                                    position: 'top-center',
                                    type: 'success',
                                    timeout: 10000,
                                    transition: 'bounce',
                                    })
                })
                .catch(() => {
                    this.isLoading = false
                    this.$createToast(
                        {
                        title: 'Attention !',
                        description: "Erreur lors de l'enregistrement des informations !"
                        },
                        {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                        })
                })
                       
            }
        }

    }

</script>
<style scoped>

.btn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  /* padding: 15px 35px; */
  /* position: relative; */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border-radius: 5px;
  text-align: center;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}

</style>
<template>
  <div v-if="inProgress == false" class="accordion" id="accordionExample" style="padding-top:20px;padding-bottom:20px;background:#fff;">
    <!-- <div class="card"> -->
      <div 
        class="card-header text-white" 
        :style="(((index % 2) == 0) ? 'background-color: #b71c1c;' : 'background-color: #9e9e9e;')"
        :id="'heading' + index" data-toggle="collapse" 
        :data-target="'#collapseSummary' + index" aria-expanded="true" 
        :aria-controls="'collapse' + index"
      >
        <strong>{{ summaryProps.question.toUpperCase() }}</strong>
        <div class="pull-right">
          <i style="color: #fff;" class="fa"></i>
        </div>
      </div>

      <div :id="'collapseSummary' + index" class="collapse show" :aria-labelledby="'heading' + index" data-parent="#accordionExample">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(child, childIndex) in summaryProps.children" :key="childIndex">
                <td>{{ child.question }}</td>
                <td>
                  <div v-if="child.type == 'text'">
                    <input v-model="summariesObj[childIndex].response" type="text" class="form-control">
                  </div>
                  <div v-else-if="child.type == 'number'">
                    <input v-model="summariesObj[childIndex].response" type="number" class="form-control">
                  </div>
                  <div v-else-if="child.type == 'select'">
                    <select v-model="summariesObj[childIndex].response" style="width:100%;" class="custom-select">
                      <option value=""></option>
                      <option v-for="(value, key) in child.value.split(';')" :key="key" :value="value">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <div v-else-if="child.type == 'image-select'">
                    
                    <image-list-input 
                      :headerLabel="child.question"
                      :imageListId="child.image_list_id" 
                      v-model="summariesObj[childIndex].response"
                      :index="summariesObj[childIndex].summary_id+''+childIndex"
                    />
                    
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="pull-left" v-if="summaryProps.categorie_id">
                    <button class="primary-button" @click="goToCategoryProduct(summaryProps.categorie_id,0)" >Voir Prestataire</button>
                  </div>
                  <div class="pull-right">
                    <button class="primary-button" @click="updateSummaryCard">ENREGISTRER</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    <!-- </div> -->
  </div>
  <div class="accordion" style="padding-top:20px;padding-bottom:20px;background:#fff;" v-else>
    <spinner  />
  </div>
</template>

<style>
  [data-toggle="collapse"] .fa:before {
  content: "\f068";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f067";
}
</style>


<script>
  import axios from 'axios'
  import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from '@/config'
  export default {
    name: 'SummaryComponent',
    props: ['summaryProps', 'index'],
    data: function() {
      return {
        summary: {
          event_id: null,
          summary_id: null,
          response: null
        },
        summariesObj: [],
        inProgress: true
      }
    },
    mounted: function() {
      this.prepareSummariesObj();
    },
    computed: {
      //
    },
    methods: {
      prepareSummariesObj: function() {
        this.inProgress = true;
        this.summaryProps.children.forEach(item => {
          let summaryObj = {
            event_id: item.pivot.event_id,
            summary_id: item.pivot.summary_id,
            response: item.pivot.response
          }
          this.summariesObj.push(summaryObj)
        })
        this.inProgress = false
      },
      updatedPercentage(customerId){
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'percentage/' + customerId,{
                    headers: {
                      Authorization: 'Bearer ' + this.$store.state.token
                    }
                  })
                    .then((response) => {
                      
                    })
                    .catch((error) => {
                        console.log('Error percentage', error)
                    });
      },
      archivated: function(customerId){
                var app = this
                this.$store.dispatch('archiveAccount', customerId)
                    .then((response) => {
                    })
                    .catch((error) => {
                    });
      },
      updateSummaryCard: function() {
        // this.$validator.validateAll()
        //     .then(valid => {
              // if(valid) {
                this.inProgress = true;
                axios.post( NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'summaries/events', this.summariesObj, {
                    headers: {
                      Authorization: 'Bearer ' + this.$store.state.token
                    }
                  })
                  .then((response) => {
                    this.inProgress = false
                    this.updatedPercentage(response.data.customer_id);
                    this.archivated(response.data.customer_id);
                    this.$createToast(
                      {
                      title: 'Opetration reussie !',
                      description: "Informations enregistrées avec succès !"
                      },
                      {
                      position: 'top-center',
                      type: 'success',
                      timeout: 10000,
                      transition: 'bounce',
                      }
                    )
                  })
                  .catch((error) => {
                    this.inProgress = false

                    this.$createToast(
                        {
                        title: 'Attention !',
                        description: "Erreur lors de l'enregistrement des informations !"
                        },
                        {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                        })
                    console.log(error)
                  })
              // }
            // });
      },
      goToCategoryProduct: function(categoryProductId,budgetId) {
                window.open('/annuaires-des-prestataires-de-services-mariage-en-cote-divoire/'+ categoryProductId+'/'+budgetId);
      }
    }
  }
</script>
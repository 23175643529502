<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="message-summary-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Résumé de votre envoi" gender="F" />
          </div>
        </div>
        <div
          class="row"
          style="position:relative;z-index:1;"
          v-if="isLoading == false && message != null"
        >
          <div
            :style="
              'position:absolute;z-index:-1;top:-15%;bottom:0;left:75%;right:0;background: url(/images/communication/wedding-planning.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'
            "
          ></div>
          <div class="col-md-6">
            <div class="col-md-12">
              <div class="form-group">
                <label for>Canal</label>
                <input
                  type="text"
                  class="form-control"
                  :value="message.channel.name"
                  readonly
                />
              </div>
            </div>

            <div
              class="col-md-12"
              v-if="message.targets && message.targets.length > 0"
            >
              <div class="form-group">
                <label for>Cible</label>
                <br />
                <span
                  v-for="(target, key) in message.targets"
                  :key="key"
                  style="margin-left:5px;margin-right:5px;"
                  class="badge badge-primary"
                >
                  <div style="margin:10px;">{{ target.name }}</div>
                </span>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for>Catégorie de message</label>
                <input
                  type="text"
                  class="form-control"
                  :value="
                    message.category.id != null
                      ? message.category.name
                      : 'Aucun'
                  "
                  readonly
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for>Modèle</label>
                <input
                  type="text"
                  class="form-control"
                  :value="
                    message.model != null ? message.model.name : 'Aucun'
                  "
                  readonly
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for>Expéditeur</label>
                <input
                  type="text"
                  class="form-control"
                  v-if="message.from"
                  :value="message.from"
                  readonly
                />
                <input
                  type="text"
                  class="form-control"
                  v-else
                  value="Moi même"
                  readonly
                />
              </div>
            </div>

            <div class="col-md-12" v-if="message.subject">
              <div class="form-group">
                <label for>Sujet</label>
                <input
                  type="text"
                  class="form-control"
                  :value="message.subject"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div
              class="col-md-12"
              v-if="
                message &&
                  message.channel &&
                  message.channel.id == 3 &&
                  message.peopleCount !== null &&
                  message.messageNumber !== null
              "
            >
              <div class="alert alert-danger text-center">
                Le nombre total de message à envoyer est :
                <span class="badge badge-secondary">{{
                  message.messageNumber +
                    " message(s)" +
                    " x " +
                    message.tab.length +
                    " invité(s) = " +
                    Number(message.messageNumber) *
                        message.tab.length +
                    " message(s)"
                }}</span>
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="
                message &&
                  message.channel &&
                  message.channel.id == 3 &&
                  message.peopleCount !== null &&
                  message.messageNumber !== null
                  
              "
            >
              <div class="alert alert-danger text-center"   >
                Vous aurez à payer :
                <span class="badge badge-secondary" v-if="messageSellingPrice == 0 ">{{
                  message.channel.selling_price +
                    " FCFA x " + Number(message.messageNumber) *
                        message.tab.length +
                     
                    " message(s) " +
                    "  = " +
                    messageSellingPrice +
                    " FCFA"
                }}</span>
                <span class="badge badge-secondary" v-else>{{
                  message.channel.selling_price +
                    " FCFA x " +
               ((Number(message.messageNumber) *  message.tab.length ) - Number(message.customerChannel.pivot.remaining_messages))  +
                    " message(s) " +
                    "  = " +
                    messageSellingPrice +
                    " FCFA"
                }}</span>
              </div>
            </div>
            <!-- <small class="col-md-12 text-danger"> -->
            <i class="text-danger">
              (Toutes les informations personnelles que vous verrez ne sont que
              des valeurs de tests. Lors de l'envoi du message à votre invité ce
              sont les valeurs renseignées au préalable dans votre carnet
              nuptial qui apparaitront.)
            </i>
            <!-- </small> -->
            <div v-if="message && message != null">
              <div v-if="message.channel != null && message.channel.id == 3">
                <div
                  style="border: 1px solid;padding: 20px;background-color: #fff;"
                >
                  {{ message.displayMessage }}
                </div>
              </div>
              <div v-else>
                <div
                  style="position: relative;text-align: center;"
                  v-if="
                    message.layout &&
                      message.layout != null &&
                      message.displayMessage != null
                  "
                >
                  <img
                    :src="base_url + message.layout.image"
                    style="width:100%;height:auto;"
                    alt
                  />
                  <div
                    class="dm-centered"
                    :style="'color:' + message.channel.color_code"
                    v-html="message.displayMessage"
                  ></div>
                </div>
              </div>
            </div>
            <div v-else>Chargement en cours ...</div>
          </div>
          <div class="col-md-12">
            <button
              class="secondary-button pull-left"
              @click="returnToTargetChoice"
            >
              <i class="fa fa-chevron-left" style="color:#fff;"></i> PRÉCÉDENT
            </button>
            <button v-if="message.customerChannel.pivot.remaining_messages >= (Number(message.messageNumber) *  message.tab.length ) " class="primary-button pull-right" @click="sendMessage">
              <i class="fa fa-check" style="color:#fff;"></i> ENVOYER
            </button>
            <button v-else class="primary-button pull-right" @click="goToPayment">
              <i class="fa fa-cart" style="color:#fff;"></i> Passer au paiement
            </button>
          </div>
        </div>
        <div v-else>
          <spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.message-summary-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}

.dm-container {
  position: relative;
  text-align: center;
  /* padding: 100px; */
  /* color: white; */
}

/* Centered text */
.dm-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: cursive;
  font-size: 12px;
}
</style>

<script>
import { defineComponent } from 'vue'
import { COMMUNICATION, API_PREFIX, API_VERSION } from "@/config";
import axios from "axios";
import { createToast } from 'mosha-vue-toastify';

export default defineComponent({
  name: "MessageSummaryComponent",
  props: ["message"],
  data: function() {
    return {
      base_url: COMMUNICATION.DEV_URL,
      isLoading: false,
      displayMessage: null,
      clientcounter:0,
      countDown : 1
    };
  },
  computed: {
    

    messageSellingPrice: {
      get: function(){
        if (Number(this.message.customerChannel.pivot.remaining_messages) < ((Number(this.message.messageNumber) *this.clientcounter))) {
          return (
            Number(this.message.channel.selling_price) *
            ((Number(this.message.messageNumber) *this.clientcounter)- Number(this.message.customerChannel.pivot.remaining_messages))
          )
        } else {
          return 0
        }
      },
      set: function(message){
        this.clientcounter = message.tab.length
      }
    }
  },
  mounted() {
   
    if (this.message) {
      this.displayMessage = this.message.body
      .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "01/12/2020")
      .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "20h00")
      .replace(
        "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE",
        "à Angré, Star 11, Abidjan, Côte d'ivoire"
      )

      .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "01/12/2020")
      .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "20h00")
      .replace(
        "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON",
        "à Angré, Star 11, Abidjan, Côte d'ivoire"
      )

      .replace("DATE_RECEPTION_DE_MARIAGE_CIVIL", "01/12/2020")
      .replace("HEURE_RECEPTION_DE_MARIAGE_CIVIL", "08h00")
      .replace("LIEU_RECEPTION_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

      .replace("DATE_RECEPTION_DE_DOT", "01/12/2020")
      .replace("HEURE_RECEPTION_DE_DOT", "10h00")
      .replace(
        "LIEU_RECEPTION_DE_DOT",
        "à Angré, Star 11, Abidjan, Côte d'ivoire"
      )

      .replace("DATE_RECEPTION_DE_MARIAGE_RELIGIEUX", "01/12/2020")
      .replace("HEURE_RECEPTION_DE_MARIAGE_RELIGIEUX", "12h00")
      .replace(
        "LIEU_RECEPTION_DE_MARIAGE_RELIGIEUX",
        "à Angré, Star 14, Abidjan, Côte d'ivoire"
      )

      .replace("DATE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "01/12/2020")
      .replace("HEURE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "12h00")
      .replace(
        "LIEU_CEREMONIE_DE_MARIAGE_RELIGIEUX",
        "à la Paroisse Ste Cécile, Vallons"
      )

      .replace("DATE_CEREMONIE_DE_DOT", "01/12/2020")
      .replace("HEURE_CEREMONIE_DE_DOT", "08h30")
      .replace(
        "LIEU_CEREMONIE_DE_DOT",
        "à Angré, Star 14, Abidjan, Côte d'ivoire"
      )

      .replace("DATE_CEREMONIE_DE_MARIAGE_CIVIL", "01/12/2020")
      .replace("HEURE_CEREMONIE_DE_MARIAGE_CIVIL", "09h00")
      .replace("LIEU_CEREMONIE_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

      .replace("CIVILITE_INVITE", "M.")
      .replace("NOM_INVITE", "Guépié")
      .replace("PRENOMS_INVITE", "Freddy")
      .replace("EMAIL_INVITE", "freddy.guepie@gmail.com")
      .replace("CONTACT_1_INVITE", "(+225) 02 00 00 01")
      .replace("CONTACT_2_INVITE", "(+225) 02 00 00 0é")

      .replace("CIVILITE_PCO", "M.")
      .replace("NOM_PCO", "Doe")
      .replace("PRENOMS_PCO", "Cedric")
      .replace("EMAIL_PCO", "cedric.doe@gmail.com")
      .replace("CONTACT_1_PCO", "(+225) 01 00 00 01")
      .replace("CONTACT_2_PCO", "(+225) 01 00 00 01")

      .replace("CIVILITE_FIANCEE", "Mlle")
      .replace("NOM_FIANCEE", "Doe")
      .replace("PRENOMS_FIANCEE", "Jane")
      .replace("EMAIL_FIANCEE", "jane.doe@gmail.com")
      .replace("CONTACT_1_FIANCEE", "(+225) 01 00 10 01")
      .replace("CONTACT_2_FIANCEE", "(+225) 01 00 11 01")

      .replace("CIVILITE_FIANCE", "M")
      .replace("NOM_FIANCE", "Doe")
      .replace("PRENOMS_FIANCE", "John")
      .replace("EMAIL_FIANCE", "john.doe@gmail.com")
      .replace("CONTACT_1_FIANCE", "(+225) 01 10 10 01")
      .replace("CONTACT_2_FIANCE", "(+225) 01 01 11 01");
    }
    
      this.countDownTimer()
  },
  methods: {
    returnToTargetChoice: function() {
      this.$emit("return-to-target-choice");
    },
    goToPayment() {
      let app = this;
      let targetIds = [];
      app.message.targets.forEach(target => {
        targetIds.push(target.id);
      });
      
      // app.createdMessage = {
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id:
      //   app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // };   
        app.message.channel_id = app.message.channel ? app.message.channel.id : null,
        app.message.label = app.message.label,
        app.message.message_category_id = app.message.category.id,
        app.message.message_model_id = app.message.model  ? app.message.model.id : null ,
        app.message.message_design_id = app.message.layout  ? app.message.layout.id : null,
        app.message.from = app.message.from,
        app.message.model = app.message.model
        app.message.subject = app.message.subject,
        app.message.body = app.message.body,
        app.message.tab = app.message.tab,
        app.message.target = targetIds.join(";")
      
      this.message.sellingPrice = this.messageSellingPrice;
      
      this.$emit('go-to-payment',app.message);
    },
    showPaymentModal() {
      this.$modal.show("payment-modal");
    },
    checkout() {
      this.$modal.hide("payment-modal");
    },
    countDownTimer() {
      if(this.countDown > 0) {
          setTimeout(() => {
              this.countDown += 1
              this.countDownTimer()
          }, 1000)
          if (this.message) {
            this.clientcounter = this.message.tab.length
            this.message.tab.length = this.clientcounter
          }
      }
    },
    sendMessage: function() {
      let app = this;
      app.isLoading = true;
      let targetIds = [];
      app.message.targets.forEach(target => {
        targetIds.push(target.id);
      });
      app.createdMessage = {
        channel_id: app.message.channel.id,
        label: app.message.label,
        message_category_id: app.message.category.id,
        message_model_id:  app.message.model != null ? app.message.model.id : null,
        message_design_id: app.message.layout != null ? app.message.layout.id : null,
        from: app.message.from,
        subject: app.message.subject,
        body: app.message.body,
        tab: app.message.tab,
        target: targetIds.join(";")
      };   
      // app.message ={
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id: app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // }; 
      // app.$emit("message-sent", this.message);

      axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages",
          app.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(response => {
          
          app.isLoading = false;
          let result = response.data;
          if (result.data.channel_id == 2) {
            let url =
              "https://joursdejoie.com/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;
            window.open(
              "https://www.facebook.com/sharer/sharer.php?app_id=548126212259891&sdk=joey&u=" +
                url,
              "_blank"
            );
          }
          // app.$Progress.finish();
          window.location.href = "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"

          this.$createToast(
                {
                  title: 'Operation reussie !',
                  description: "Message envoyé avec succès  !"
                },
                {
                  position: 'top-center',
                  type: 'success',
                  timeout: 10000,
                  transition: 'bounce',
                })
        })
        .catch(error => {
          console.log(error);
          app.isLoading = false;
          // app.$Progress.fail();
          app.$moshaToast("Erreur détectée lors de l'envoie du message !");
        });
    },
    

  }
});
</script>

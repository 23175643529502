<template>
  <div id="main" class="bg-container-contacts">
    <vue-progress-bar></vue-progress-bar>
    <Breadcrumb bgClass="bg-image--contact-1" titre="Contacts"></Breadcrumb>
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-12 text-center pb-5">
          <h2 style="color:#b71c1c;font-weight:600;padding-top:30px;">
            NOS CONTACTS<br />
            <img
              class="img-fluid"
              src="/images/icon-flower.png"
              style="width:100px;height:auto;margin-top:-25px;margin-bottom:-25px;"
            />
          </h2>
        </div>
        <div class="col-md-4 text-center">
          <div class="col-md-12 pb-5">
            <img src="/images/contacts/phone.png" class="pb-3" alt="" />
            <h4 style="color:#b71c1c;">Nº DE TÉLÉPHONE</h4>
            <br />
            <p style="font-size: 16px;">(+225) 05 75 44 06 06</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="col-md-12 pb-5">
            <img src="/images/contacts/email.png" class="pb-3" alt="" />
            <h4 style="color:#b71c1c;">ADRESSE EMAIL</h4>
            <br />
            <p style="font-size: 16px;">info@joursdejoie.com<br /></p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="col-md-12 pb-5">
            <img src="/images/contacts/rss.png" class="pb-3" alt="" />
            <h4 style="color:#b71c1c;">RÉSEAUX SOCIAUX</h4>
            <br />
            <a
              href="https://web.facebook.com/joursdejoie.ci/"
              target="_blank"
              class="btn-social btn-facebook"
            >
              <i class="fa fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/joursdejoie_officiel"
              target="_blank"
              class="btn-social btn-instagram"
            >
              <i class="fa fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCtucAiXVjWmpgWfadPS8x1Q"
              target="_blank"
              class="btn-social btn-youtube"
            >
              <i class="fa fa-youtube"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=2250575440606"
              target="_blank"
              class="btn-social btn-wechat "
            >
              <i class="fa fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-image--contact-2" style="height:auto; padding:30px 0;">
      <div class="container">
        <div class="row" style="vertical-align:middle;">
          <div
            class="col-md-6 mb-3 d-flex flex-column justify-content-around text-center"
            style="color:#fff;"
          >
            <h2 style="color :#fff;font-size:35px;">
              Des questions à nous poser ?<br />Ecrivez-nous.
            </h2>
            <!-- <p class="pt-50">data structures can be large, a good UI trick for displaying them is to hide all but the root node so the user can expand/contract nodes as needed.
                        To do this, we'll add a local state property showChildren. If false, child nodes will not be rendered. This value should be toggled by clicking the node, so we'll need a click event listener method toggleChildren to manage this.</p> -->
          </div>
          <div class="col-md-6">
            <div class="card" style="border-radius:0; opacity:0.9;">
              <div class="card-body">
                <form v-on:submit.prevent="contactUs">
                  <div class="row">
                    <div class="col-md-12">
                      <text-input
                        label="Nom"
                        name="name"
                        type="text"
                        :rules="'required'"
                        :isRequired="true"
                        v-model="contact.name"
                      />
                      <masked-input
                        label="Nº de téléphone"
                        name="phone_numbers"
                        type="text"
                        :rules="'required|min:18|max:22'"
                        :isRequired="true"
                        placeholder="(+225) XX XX XX XX XX / XX XX XX XX XX"
                        v-model="contact.phone_numbers"
                        :mask="'+{(000)} 00 00 00 00 00 / +{(000)} 00 00 00 00 00'"
                      />
                      <select-input
                        label="Sexe"
                        name="sex"
                        type="text"
                        :rules="'required'"
                        :isRequired="true"
                        :options="sexs"
                        v-model="contact.sexe"
                      />
                      <text-input
                        label="Email"
                        name="email"
                        type="email"
                        :rules="'required|email'"
                        :isRequired="true"
                        v-model="contact.email"
                      />
                      <textarea-input
                        label="Message"
                        name="msg"
                        :rules="'required'"
                        :isRequired="true"
                        v-model="contact.msg"
                      />
                      <button class="primary-button" type="submit">
                        <i
                          style="color:#fff;"
                          class="lnr lnr-checkmark-circle"
                        ></i
                        >&nbsp;Envoyer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Newsletter></Newsletter>
    <!-- <NewsletterBox
      :activeOverlay="activeOverlay"
      :sexe="contact.sexe"
      :email="contact.email"
    ></NewsletterBox> -->
  </div>
</template>
<style scoped>
@media (min-width: 1025px) {
  .container {
    width: 80% !important;
  }
  .container.c2 {
    width: 75% !important;
  }
}
.item {
  padding: 25px 0;
}
.single-contact-form.space-between input {
  margin: 0 10px;
}

.single-contact-form input,
.single-contact-form select {
  border: 1px solid #c1c1c1;
  border-radius: 0;
  height: 42px;
  outline: medium none;
  padding: 0 0px;
  width: 100%;
  border-bottom: 1px solid #c1c1c1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.single-contact-form textarea {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  border-radius: 0;
  max-width: 100%;
  padding: 10px;
  height: 100px;
  padding: 0px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  width: 100%;
  outline: none;
}

.single-contact-form input::placeholder {
  color: #b71c1c;
  font-weight: bold;
}

.single-contact-form textarea::placeholder {
  color: #b71c1c;
  font-weight: bold;
  line-height: 50px;
}
.btn-submit {
  background: #b71c1c none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: uppercase;
}

.single-contact-form input.is-danger,
.single-contact-form textarea.is-danger,
.single-contact-form select.is-danger {
  border-bottom: 1px solid #b71c1c;
}
.bg-container-contacts {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-contacts .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-contacts::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/contact/contact.png");
  background-size: cover;
  opacity: 0.1;
}
.bg-container-contacts-section-right {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}
.bg-container-contacts-section-right::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/contacts/contact-right.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  /* opacity: 0.5; */
}
.bg-container-contacts-section-left {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}
.bg-container-contacts-section-left::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/contacts/contact-left.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left;
  /* opacity: 0.1; */
}
</style>
<script>
import axios from "axios";
import { CONTACT, API_PREFIX, API_VERSION } from "@/config";
import NewsletterBox from "@/components/NewsletterBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useMeta } from 'vue-meta'
export default {
  setup () {
    useMeta({
      title: "Contacts, Assistant digital d'organisation de mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Organisation de mariage en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Organisation de mariage en Côte d’Ivoire - Agence d’organisation de mariage en Côte d’Ivoire - Agence d’organisation de mariage en Côte d’Ivoire, Jours de joie, Paraclet Sarl, digital event planner,wedding planner",
        },
      ],
    })
  },

  components: {
    NewsletterBox,
    Breadcrumb,
  },
  data() {
    return {
      contact: {
        name: "",
        email: "",
        msg: "",
        sexe: null,
        phone_numbers: null,
      },
      activeOverlay: false,
      sexs: [
        {
          id: "Homme",
          name: "Homme",
        },
        {
          id: "Femme",
          name: "Femme",
        },
      ],
    };
  },
  created: function() {
    // this.$Progress.start();
  },
  mounted: function() {
    // this.$Progress.finish();
  },

  methods: {
    contactUs() {
      let app = this;

          axios
            .post(
              CONTACT.DEV_URL + API_PREFIX + API_VERSION + "contact_us",
              app.contact
            )
            .then(function(ddd) {

              app.$moshaToast("Votre message à été envoyé avec succès");
              app.activeOverlay = true;
              app.contact = {
                name: "",
                email: "",
                msg: "",
                sexe: null,
                phone_numbers: null,
              };
            })
            .catch(function(error) {
              console.log(error);
            });
       
    },
  },
};
</script>

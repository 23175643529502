<template>
  <div>
    <div class="" style="display:flex;flex-direction: column; align-items: center;justify-content: space-between;margin-top: 80px;">
        <h2 style="text-align: center;color:goldenrod;margin-top:40px">TELECHARGEZ GRATUITEMENT</h2>
        <div style="text-align: center;margin-top:40px">
        <img src="/images/logo.png" style="width:90%;"/>
    </div>
    <a href="https://apps.apple.com/ci/app/jours-de-joie/id6504404907" style="color:white" class="download_apple"></a>
    <a href="https://play.google.com/store/apps/details?id=com.joursdejoie.app" style="color:white" class="download_android"></a>

    <h2 style="text-align: center;color:rgb(183, 28, 28);margin-top:40px">EN CLIQUANT ICI</h2>
    <div style="margin-top:40px">
      <div class="" style="width:100%;display:flex;flex-direction: row;align-items: center;justify-content: center;">
        <div style="text-align: center;" class="left-download-icon">
            <h4>Android</h4>
            <a
          href="https://play.google.com/store/apps/details?id=com.joursdejoie.app"
          target="_blank" style="text-align:center"
        >
          <img src="/images/google_play.png" alt="" width="70%" />
        </a>
        </div>
        <div style="text-align:center" class="right-download-icon">
            <h4>IOS</h4>
            <a
          href="https://apps.apple.com/ci/app/jours-de-joie/id6504404907"
          target="_blank" style="text-align:center"
        >
          <img src="/images/apple_logo_download.png" width="75%" alt="" />
        </a>
        </div>
      </div>
    </div>
    <div style="margin-top:40px;display: flex;justify-content: center;">
        <p style="text-align: center;font-size: 140%;width:60%">
          La première plateforme qui vous permet de réaliser séreinement vos
          évènements heureux (Mariage, Anniversaire, Baptême)
        </p>
      </div>
  </div>
  </div>
</template>
<style>
  @keyframes up-down {
    from { margin-top: 10px;}
    to {margin-bottom:10px;}
  }

  .left-download-icon ,.right-download-icon{
    animation-name: up-down;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate ;
    
  }

</style>
<script>
export default {
  name: "DownloadAppView",
  data() {
    return {};
  },
  mounted(){

    let mobile = this.detectMob();
    if(mobile=="Android"){
      document.querySelector(".download_android").click();
    }else if(mobile=="iOS"){
      document.querySelector(".download_apple").click();
    }else{
    }
  },
  methods:{

    detectMob: function (){
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    // Check for mobile devices
    const isMobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });

    if (isMobile) {
        // Check if the detected device is Android or iOS
        if (navigator.userAgent.match(/Android/i)) {
            return "Android";
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            return "iOS";
        } else {
            return "Unknown mobile device"; // In case a new mobile device emerges that isn't covered
        }
    } else {
        // Not a mobile device
        return "Not a mobile device";
    }
}
    
  }
};
</script>

<template>
  <div style="width: 100%;background-color: #fff;">
    <form
      style="margin: 0 auto;height: 100vh;background-image: url('/images/backgrounds/auth/auth.png');background-size: contain;z-index: -999;"
      class="p-5"
      id="form-input"
      @submit.prevent="onSubmit"
    >
      <div class="row" style="background-color: #fff;z-index: 999;">
        <div class="col-md-12">
          <div style="text-align: center;" class="pt-5 pb-4">
            <h3 class="font-weight-light">Restauration du Mot de passe</h3>
          </div>
        </div>
        <div class="col-md-12" v-if="error == true">
          <div class="alert alert-danger text-center">
            Oups... Email / Mot de passe incorrect.
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              class="auth-input"
              type="password"
              v-model="user.password"
              placeholder="Nouveau mot de passe"
              required
            />
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              class="auth-input"
              type="password"
              v-model="user.password_confirmation"
              placeholder="Confirmation de mot de passe"
              required
            />
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group text-center">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 15px;"
              v-if="!isLoading"
            >
              ENVOYER
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 15px;"
              disabled
              v-else
            >
              <spinner size="small" line-fg-color="#fff" />
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped></style>

<script>
import axios from "axios";
import { AUTH, API_PREFIX, API_VERSION } from "@/config";
export default {
  name: "PasswordReset",
  data: function() {
    return {
      user: {
        email: null,
        password: null
      },
      error: false,
      isLoading: false,
      status: null
    };
  },
  components: {
    //
  },
  mounted: function() {
    //
  },
  methods: {
    onSubmit: function() {
      this.isLoading = true;
      axios
        .post(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "password-reset/" +
            this.$route.params.token +
            "/" +
            this.$route.params.email,
          {
            password: this.user.password,
            password_confirmation: this.user.password_confirmation
          }
        )
        .then(() => {
          this.isLoading = false;
          this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Mot de passe restauré avec succès !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
          this.$router.push("/connexion");
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
      
          this.$createToast(
            {
            title: 'Attention !',
            description: "Erreur lors de la restauration du mot de passe !"
            },
            {
            position: 'top-center',
            type: 'error',
            timeout: 10000,
            transition: 'bounce',
            })
        });
    }
  }
};
</script>
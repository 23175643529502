<template>
  <div class="container p-3" style="padding: 25px;">
    <big-title label="INVITÉS" />
    <br />
    <div v-if="isLoading == false">
      <div v-for="(guest, key) in guestsForms" :key="key">
        <br />
        <div class="guests-container p-3">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <sub-title :label="'INFORMATIONS INVITÉ'" :gender="'F'" />
            </div>
            <div class="col-md-2">
              <button
                class="pull-right primary-button"
                @click="deleteGuestRow(key)"
              >
                <i style="color: #fff;" class="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <select-input
                label="Civilité"
                :name="'civility' + key"
                type="text"
                :rules="'required'"
                :isRequired="true"
                :options="civilities"
                v-model="guestsForms[key].civility"
              />
            </div>
            <div class="col-md-3">
              <text-input
                label="Nom"
                :name="'last_name' + key"
                type="text"
                :rules="'required'"
                :isRequired="true"
                v-model="guestsForms[key].last_name"
              />
            </div>
            <div class="col-md-6">
              <text-input
                label="Prénoms"
                :name="'given_names' + key"
                type="text"
                :rules="'required'"
                :isRequired="true"
                v-model="guestsForms[key].given_names"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <select-input
                label="Statut"
                :name="'status' + key"
                type="text"
                :rules="'required'"
                :isRequired="true"
                :options="guests"
                v-model="guestsForms[key].guest_id"
              />
            </div>
            <div class="col-md-3">
              <text-input
                label="Email"
                :name="'email' + key"
                type="email"
                :rules="'email'"
                :isRequired="false"
                v-model="guestsForms[key].email"
              />
            </div>
            <div class="col-md-3">
              <masked-input
                label="Téléphone principal"
                :name="'phone_number_1' + key"
                type="text"
                :rules="'required|min:18|max:22'"
                :isRequired="true"
                v-model="guestsForms[key].phone_number_1"
                placeholder="(+225) 00 00 00 00 00"
                :mask="'+{(000)} 00 00 00 00 00'"
              />
            </div>
            <div class="col-md-3">
              <masked-input
                label="Téléphone secondaire"
                :name="'phone_number_2' + key"
                type="text"
                :rules="'min:18|max:22'"
                :isRequired="false"
                v-model="guestsForms[key].phone_number_2"
                placeholder="(+225) 00 00 00 00"
                :mask="'+{(000)} 00 00 00 00 00'"
              />
            </div>
          </div>
        </div>
        <br />
      </div>
      <button class="pull-right primary-button" @click="addGuestRow">
        <i style="color: #fff;" class="fa fa-plus"></i>
      </button>
    </div>
    <div v-else>
      <spinner  />
    </div>
    <br />
    <center>
      <button class="default-button" @click="goToBack">RETOUR</button>&nbsp;
      <button class="primary-button" @click="store">ENREGISTRER</button>&nbsp;
      <button class="secondary-button" @click="cancel">ANNULER</button>
    </center>
  </div>
</template>

<script>
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import axios from "axios";
import { IMaskDirective } from 'vue-imask';
import { useMeta } from 'vue-meta'

export default {

  name: "GuestsCreate",
  setup () {
    useMeta({
      title:
      "Création d’un invité - Carnet Nuptial - Invités - Établissez en toute quiétude, la liste des invités de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment faire sa liste d’invités à mon mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, organisation de mariage, prestataire de mariage,combien d’invités à mon mariage, comment faire sa liste d’invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage,",
        },
      ],
    })
  },
  data: function() {
    return {
      mask: {
          mask: '+{(225)} 00 00 00 00 00',
          lazy: true
        },
      guest: {
        event_id: this.$route.params.eventId,
        guest_id: null,
        civility: null,
        last_name: null,
        given_names: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
        has_confirmed: null,
      },
      civilities: [
        {
          id: "Monsieur",
          name: "Monsieur",
        },
        {
          id: "Madame",
          name: "Madame",
        },
        {
          id: "Mademoiselle",
          name: "Mademoiselle",
        },
      ],
      guests: null,
      guestsForms: [],
      isLoading: false,
    };
  },
  mounted: function() {
    this.getGuests();
  },
  directives: {
      imask: IMaskDirective
  },  
  methods: {
    onAccept (e) {
        const maskRef = e.detail;
        this.value = maskRef.value;
        
      },
      onComplete (e) {
        const maskRef = e.detail;
        
      },
    goToBack: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    cancel: function() {
      this.$router.go();
    },
    updatedPercentage(customerId) {
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "percentage/" +
            customerId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error percentage", error);
        });
    },
    archivated: function(customerId) {
      var app = this;
      this.$store
        .dispatch("archiveAccount", customerId)
        .then((response) => {})
        .catch((error) => {});
    },
    store: function() {
      let app = this;
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
          this.isLoading = true;
          axios
            .post(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-store",
              this.guestsForms,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.isLoading = false;
              app.updatedPercentage(response.data.customer_id);
              app.archivated(response.data.customer_id);
              this.$router.push(
                "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
              );
             
              this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Informations ajouté avec succès !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              })
            })
            .catch((error) => {
              console.log(error.response.data);
              this.isLoading = false;
              this.$createToast(
                  {
                  title: 'Attention !',
                  description: "Erreur lors de l'ajout de l'invité !"
                  },
                  {
                  position: 'top-center',
                  type: 'error',
                  timeout: 10000,
                  transition: 'bounce',
                  })
            });
      //   }
      // });
    },
    addGuestRow: function() {
      this.guestsForms.push({
        event_id: this.$route.params.eventId,
        guest_id: null,
        civility: null,
        last_name: null,
        given_names: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
      });
    },
    deleteGuestRow: function(key) {
      this.guestsForms.splice(key, 1);
    },
    getGuests: function() {
      this.isLoading = true;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.guests = response.data;
          this.addGuestRow();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.guests-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
.icon {
  color: #fff;
}
</style>

<template>
  <div>
    <Breadcrumb bgClass="bg-image--welcome" titre="F.A.Q"></Breadcrumb>
    <div class="container" style="padding-bottom:20px;">
      <div class="row" style="padding-top:20px;padding-bottom:20px;">
        <div class="col-md-12">
          <div v-if="content != null" v-html="content"></div>
          <div v-else>
            <spinner size="medium" line-fg-color="#b71c1c" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CONTACT, API_PREFIX, API_VERSION } from "@/config";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useMeta } from 'vue-meta'
export default {
  name: "FAQ",
  setup () {
    useMeta({
      title: "Foires aux questions",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Organisation de mariage en Côte d’Ivoire"
        },
        {
          name: "keywords",
          content:
            "Agence d’organisation de mariage en Côte d’Ivoire - Agence d’organisation de mariage en Côte d’Ivoire, wedding planner, choix de mairies, définition du budget de mariage en Côte d'Ivoire, carnet nuptial,wedding planner"
        }
      ]
    })
  },
  components: {
    Breadcrumb
  },
  data: function() {
    return {
      content: null
    };
  },
  mounted: function() {
    let app = this;
    app.getCGU();
    // app.$ua.trackView("FAQ");
  },
  methods: {
    getCGU: function() {
      let app = this;
      axios
        .get(CONTACT.DEV_URL + API_PREFIX + API_VERSION + "info_site/5")
        .then(function(response) {
          app.content = response.data.value;
        })
        .catch(function() {
        });
    }
  }
};
</script>
